<script>
	import { globalAlert } from '$lib/stores/globalAlert';
	import { browser } from '$app/env';

	import SmallInfoCircleIcon from '$lib/components/Icons/SmallInfoCircleIcon.svelte';
	import CloseIcon from '$lib/components/Icons/CloseIcon.svelte';

	const DEFAULT_TIMEOUT = 3500;

	$: browser && $globalAlert?.message && triggerAlert($globalAlert);

	let show = false;
	let type = 'info';
	let message = 'Testing';
	let linkHref = '';
	let linkText = '';
	let timeout = undefined;
	let offsetStyle = '';

	let timeoutInstance = undefined;

	// FUNCTIONS

	const triggerAlert = (alert) => {
		if (!alert?.message) {
			return;
		}

		type = alert.type ?? 'info';
		message = alert.message;
		timeout = alert.timeout ?? DEFAULT_TIMEOUT;
		$globalAlert = {};

		openAlert({
			type,
			message,
			timeout,
		});
	};

	export const openAlert = (opts) => {
		// Clear the alert store
		$globalAlert = {};

		// Set the alert
		type = opts.type ?? 'info';
		message = opts.message ?? 'An error occured...';
		timeout = opts.timeout ?? undefined;
		show = true;

		if (timeoutInstance) {
			clearTimeout(timeoutInstance);
		}

		// Optionally close on end of timeout
		if (timeout) {
			timeoutInstance = setTimeout(() => (show = false), timeout);
		}
	};

	export const closeAlert = () => {
		show = false;
	};

	const sharedBodyClasses = `flex p-4 mb-4 rounded-lg z-50 fixed alert-sticky transition-opacity ease-in-out duration-300`;
	const sharedButtonClasses = `ml-auto -mx-1.5 -my-1.5 rounded-lg focus:ring-2 p-1.5 inline-flex h-8 w-8`;

	$: bodyColorClasses = getColorClasses(type);
	$: buttonColorClasses = getButtonColorClasses(type);

	$: bodyClasses = `${sharedBodyClasses} ${bodyColorClasses}`;
	$: buttonClasses = `${sharedButtonClasses} ${buttonColorClasses}`;

	const getColorClasses = (type) => {
		if (type == 'info' || type == 'blue' || type == 'blue') return 'bg-blue-100 text-blue-700';
		if (type == 'danger' || type == 'error' || type == 'red') return 'bg-red-100 text-red-700';
		if (type == 'success' || type == 'green') return 'bg-green-100 text-green-700';
		if (type == 'warning' || type == 'caution' || type == 'yellow') return 'bg-yellow-100 text-yellow-700';
		return 'bg-blue-100';
	};

	const getButtonColorClasses = (type) => {
		if (type == 'info' || type == 'blue' || type == 'blue')
			return 'bg-blue-100 text-blue-500 focus:ring-blue-400 hover:bg-blue-200';
		if (type == 'danger' || type == 'error' || type == 'red')
			return 'bg-red-100 text-red-500 focus:ring-red-400 hover:bg-red-200';
		if (type == 'success' || type == 'green') return 'bg-green-100 text-green-500 focus:ring-green-400 hover:bg-green-200';
		if (type == 'warning' || type == 'caution' || type == 'yellow')
			return 'bg-yellow-100 text-yellow-500 focus:ring-yellow-400 hover:bg-yellow-200';
		return 'bg-blue-100 text-blue-500 focus:ring-blue-400 hover:bg-blue-200';
	};
</script>

{#if show}
	<div class="{bodyClasses} {show ? 'opacity-100' : 'hidden opacity-0'}" role="alert" style="z-index: 1000; {offsetStyle}">
		<SmallInfoCircleIcon class="h-5 w-5 flex-shrink-0 animate-bounce" />
		<div class="ml-3 text-sm font-medium">
			{message}
			{#if linkHref != ''}
				<a href={linkHref} class="font-semibold underline hover:text-blue-800 dark:hover:text-blue-900">
					{linkText}
				</a>
			{/if}
		</div>
		<button type="button" class={buttonClasses} data-collapse-toggle="i-alert" aria-label="Close" on:click={closeAlert}>
			<CloseIcon class="h-5 w-5" />
		</button>
	</div>
{/if}
