<script>
	import { appStores } from '$lib/stores/appStores';
	import { browser } from '$app/env';
	import { onMount } from 'svelte';

	const languages = [
		{
			name: 'English',
			code: 'en',
			flag: '🇨🇦',
		},
		{
			name: 'Chinese (Traditional)',
			code: 'zh-tw',
			flag: '🇹🇼',
		},
	];

	$: index = languages.findIndex((lang) => lang.code === $appStores?.language) ?? 0;

	const nextLanguage = (index) => {
		if (index === languages.length - 1) {
			$appStores.language = languages[0]?.code;
			location.reload();
			return;
		}

		$appStores.language = languages[index + 1]?.code;
		location.reload();
	};

	onMount(() => {
		// If the language is not set then set it
		if (browser) {
			if (!$appStores.language) {
				$appStores.language = languages[0]?.code;
			}
		}
	});
</script>

{#each languages as lang, i}
	{#if i === index}
		<div class="cursor-pointer text-2xl hover:scale-110" on:click={() => nextLanguage(i)}>
			{lang.flag}
		</div>
	{/if}
{/each}
